// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$smartbox-fe-primary: mat.define-palette(mat.$indigo-palette);
$smartbox-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$smartbox-fe-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$smartbox-fe-theme: mat.define-light-theme(
    (
        color: (
            primary: $smartbox-fe-primary,
            accent: $smartbox-fe-accent,
            warn: $smartbox-fe-warn,
        ),
    )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($smartbox-fe-theme);

/* You can add global styles to this file, and also import other style files */

/*!
 * Start Bootstrap - Landing Page v5.0.0 (https://startbootstrap.com/template-overviews/landing-page)
 * Copyright 2013-2018 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-landing-page/blob/master/LICENSE)
 */
 
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "variables";

@font-face {
    font-family: "Lato";
    src: url("../assets/fonts/Lato/Lato-Regular.ttf");
}
:root {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: #fff;
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 1px;
    --bs-modal-border-radius: 0.5rem;
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-modal-inner-border-radius: calc(0.5rem - 1px);
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 1px;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 1px;
}

// html,
// body {
//     height: 100%;
// }

body {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    font-family: "Lato" !important;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    height: 100%;
    overflow: hidden;
}

nav {
    flex: 70px 0 0;
}

section {
    flex: 200px 1 0;
}

body,
.btn,
span {
    font-size: 1.2rem;
}

.bg-light {
    background: #ffffff !important;
}

button.circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: none;
}

button.circle-small {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: none;
    margin: 10px;
}

.userOverview .form-group a {
    width: calc(50% - 20px);
    margin: 5px 10px;
}

.userOverview .form-group {
    display: flex;
    flex-wrap: wrap;
}

.modal-header {
    h5 {
        font-family: "Lato" !important;
        font-weight: 600;
        font-size: 18px;
    }
}

h5 {
    font-family: "Lato" !important;
    font-weight: 600;
    font-size: 18px;
}
.form-check {
    margin: 10px 0;
}

.screenMainSection {
    max-width: 65%;
    overflow: hidden;
}

.screenMainSection .row .col-md-6 {
    max-width: calc(50% - 1rem);
}

.screenMainSection .rowGroup {
    margin-bottom: 10px;
    padding-top: 10px;
    border-top: 1px solid #ccc;
}

.screenMainSection .rowGroup .rowGroupHeader {
    font-size: 15px;
    margin-left: 10px;
    margin-bottom: 10px;
    color: #24597a;
    font-weight: 600;
}

#blueQuestionModal {
    z-index: 1049;
}

.ordernumbermodal .modal-header .orderCounter {
    margin-left: 100px;
}

.ordernumbermodal .modal-header .orderCounterValue {
    display: inline-block;
}

.ordernumbermodal .modal-dialog {
    max-width: calc(65% - 90px);
}

.ordernumbermodal .modal-body,
.suncircle_zaaglijstModal .modal-body {
    overflow-y: scroll;
    max-height: 500px;
}

.ordernumbermodal .modal-body div {
    display: flex;
    justify-content: center;
}

.ordernumbermodal .modal-body div .btn > div.extraOrderInfo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    margin-top: 5px;
    font-weight: 600;
    position: relative;
}

.ordernumbermodal .modal-body div .btn.active > div.extraOrderInfo {
    font-size: 24px;
    margin-top: 0px;
}

.ordernumbermodal
    .modal-content
    .modal-body
    div
    .btn.active:first-child
    > div.extraOrderInfo
    > div.extraOrderInfo-materialdescription,
.ordernumbermodal
    .modal-content
    .modal-body
    div
    .btn.active:first-child
    > div.extraOrderInfo
    > div.extraOrderInfo-FGMaterial,
.ordernumbermodal
    .modal-content
    .modal-body
    div
    .btn.active:first-child
    > div.extraOrderInfo
    > div.extraOrderInfo-Motortype,
.ordernumbermodal
    .modal-content
    .modal-body
    div
    .btn.active:first-child
    > div.extraOrderInfo
    > div.extraOrderInfo-Bedieningskant {
    display: block !important;
}

.ordernumbermodal .modal-body div .btn > div.extraOrderInfo .newunit:not(:empty) {
    color: red;
    font-size: 16px;
    display: inline-block;
    margin: auto 0;
    text-transform: uppercase;
    position: absolute;
    right: 0;
}

.ordernumbermodal .modal-content.autorun .modal-body > div {
    justify-content: flex-start;
}

.ordernumbermodal .modal-content.autorun .modal-body div .btn.active:first-child {
    font-weight: 600;
    font-size: xx-large;
}

.ordernumbermodal .modal-body > div > button:first-child {
    width: 100%;
    margin-right: 10px;
}

.ordernumbermodal .modal-body > div > button {
    margin-bottom: 10px;
}

.ordernumbermodal .modal-body > div > button > i {
    margin-right: 10px;
}

.ordernumbermodal .modal-body > div > button.green,
.ordernumbermodal .modal-body > div > button.darkblue {
    color: #fff;
}

.ordernumbermodal .modal-content .modal-body > div > button:nth-child(2) {
    display: none;
}

.ordernumbermodal .modal-content.autorun .modal-body > div > .btn.active + button:nth-child(2) {
    display: block;
}

.ordernumbermodal .modal-content.autorun .modal-body > div > button:first-child {
    width: 75%;
}

.ordernumbermodal .modal-content.autorun .modal-body > div > button:nth-child(2) {
    width: calc(25% - 10px);
}

.ordernumbermodal .modal-content.autorun .modal-footer .btn-primary {
    display: none;
}

.ordernumbermodal_docked {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    background: lightpink;
    padding: 20px;
    justify-content: center;
}

.ordernumbermodal_docked > div {
    margin: auto 20px;
}

.ordernumbermodal_docked .btn {
    color: #fff;
    background: #000;
}

.suncircle_zaaglijstModal .zaagselector {
    flex: 1 100%;
    margin-bottom: 10px;
}

.suncircle_zaaglijstModal .zaagselector > div {
    border: 1px solid #24597a;
    display: flex;
    width: 100%;
    height: 100%;
}

.suncircle_zaaglijstModal .zaagselector > div > .active {
    background: #24597a;
    color: #fff;
}

.suncircle_zaaglijstModal .zaagselector > div > div {
    width: 50%;
    padding: 3px;
    cursor: pointer;
}

.suncircle_zaaglijstModal .modal-body button span {
    display: block;
    font-size: 14px;
}

.suncircle_zaaglijstModal .modal-body button.parked:before {
    content: "\f28b";
    font-family: "Lato" !important;
    margin-right: 20px;
}

.suncircle_zaaglijstModal .modal-body button.processing:before {
    content: "\f144";
    font-family: "Lato" !important;
    margin-right: 20px;
}

.suncircle_zaaglijstModal .modal-body button.done:before {
    content: "\f058";
    font-family: "Lato" !important;
    margin-right: 20px;
}

.suncircle_zaaglijstModal .modal-body button {
    width: 80%;
    margin-bottom: 10px;
}

#attachmentModal .modal-dialog {
    max-width: 99%;
    height: calc(100% - 50px);
}

#attachmentModal .modal-dialog .modal-content {
    height: 100%;
}

.recenteMeldingen {
    font-size: 13px;
}

.red {
    background-color: red;
}

.yellow {
    background-color: orange;
}

.green {
    background-color: green;
}

.blue {
    background-color: blue;
}

.grey {
    background-color: #ccc;
}

.menublue {
    background-color: #547c96;
}

.darkblue {
    background-color: #24597a;
}

button.circle.grey {
    background-color: rgb(239, 239, 239);
}

.greyed-out {
    filter: grayscale(1);
}

.mainScreen {
    height: calc(100% - 60px);
}

#actionRed,
#actionYellow,
#actionGreen,
#actionBlue {
    cursor: hand;
}

.statusScreen {
    border-radius: 0px;
    color: #fff;
    font-weight: bold;
    margin-left: 28px;
    max-width: calc(18.66667% - 60px);
}

.blueStatusBar p {
    font-size: 22px;
    word-break: break-word;
    color: #fff;
}

.blueStatusBar button.btn-secondary {
    font-size: 18px;
    padding: 8px 25px;
    font-weight: 600;
    background-color: #fff;
    color: #000;
}

.stopTimer {
    display: none;
    width: 80%;
}

.features-icons .row.actionButton {
    padding: 8px;
    border: 1px solid #24597a;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #e8e6e6;
    min-height: 50px;
}

.row.actionButton,
.orderTabs div:not(:first-child, .active, .scrollable) {
    cursor: pointer;
}

.row.mb-2.actionButton {
    padding: 8px 25px;
}

span.button-text-large {
    font-size: 22px;
    color: #24597a;
    font-weight: 400;
}

span.button-text-small {
    font-size: 18px;
    color: #24597a;
    font-weight: 400;
    overflow-wrap: anywhere;
}

#userName {
    font-size: 24px;
    font-weight: 600;
}

.action small,
span {
    clear: both;
}

.btn.inactive,
.closeOrderBtn.inactive {
    pointer-events: none;
    opacity: 0.6;
    filter: alpha(opacity=50);
    zoom: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
}

.closeOrderBtn.inactive {
    background-color: #ccc !important;
}

.timer,
.activeColorText {
    font-size: 18px;
    font-weight: 400;
}

.activeColorText {
    color: #fff;
    width: 100%;
    padding: 0 5px;
}

.activeColorTextDescription {
    font-size: small;
    width: 100%;
}

.titleBar {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}

.titleBar > div {
    padding: 12px;
}

.titleBar.urenRegistratie > div i {
    margin-right: 10px;
}

.titleBar.urenRegistratie > div:nth-child(2) {
    cursor: pointer;
    background-color: #a9bdca;
}

.titleBar.urenRegistratie > div:nth-child(2):hover {
    background-color: #91a8b7;
}

.titleBar a,
.titleBar a:hover,
.titleBar a:visited {
    color: #fff;
    text-decoration: none;
}

.orderTabs {
    background: rgba(84, 124, 150, 0.5);
    margin-right: 30px;
    max-width: calc(18.66667% - 30px);
    padding-right: 0;
}

.orderTabs div.row {
    padding: 15px 10px;
    margin: 0;
    height: 70px;
    border-bottom: 1px solid #fff;
    display: flex;
    flex-wrap: wrap;
}

.orderTabs div.row.addOrder {
    color: #24597a;
}

.orderTabs > div.row:first-child {
    height: 53.6px;
    padding: 5px 10px;
    display: block;
    background: #547c96;
    margin-left: -15px;
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    text-align: left;
}

.orderTabs div.row.overig {
    margin-left: -15px;
}

.orderTabs div.row.overig i {
    margin-top: 7px;
}

.orderTabs div.row.action:first-child {
    color: #24597a;
}

.orderTabs div.row span {
    display: block;
    margin: 0 auto;
    font-size: 16px;
    overflow: hidden;
    max-width: calc(100% - 15px);
    text-overflow: ellipsis;
    // direction: rtl;
    text-align: left;
}

.orderTabs div.row .addOrder {
    cursor: pointer;
    position: absolute;
    right: 10px;
}

.orderTabs div.row .addOrder span {
    max-width: initial;
    font-size: 24px;
}

.orderTabs div.row.addOrder span {
    font-size: 20px;
    line-height: 39px;
}

.orderTabs div.row.active {
    background: #fff;
    border: solid 1px #24597a;
    margin-right: 0;
    border-right: none;
    color: #24597a;
}

.orderTabs button.circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: none;
    margin: auto 0;
}

.orderTabs .scrollable {
    overflow-y: scroll;
    //height: calc(100vh - 363px);
    margin-left: -8px;
    // margin-left: -22px;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
}

.orderTabs .scrollable::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.orderTabs .overig {
    background-color: #24597a;
    color: #fff;
    height: 51px;
}

.agenda-container {
    height: calc(100% - 130px);
}

.agenda-container .col-md-2 div.halfheight {
    padding: 5px;
    height: calc(100% / 24);
}

.progressbar {
    border: 1px solid #24597a;
    height: 20px;
    margin-bottom: 8px;
    padding: 0;
}

.progressbar > div {
    background-color: #24597a;
    height: 100%;
}

.agenda-container .col-md-2 div:not(.halfheight) {
    height: calc(100% / 12);
}

.agenda-container .agenda-items {
    padding: 0;
}

.agenda-container .agenda-items > div.emptyrow {
    height: calc(100% / 12);
    border-left: 1px solid #24597a;
    border-top: 1px solid #24597a;
}

.agenda-container .agenda-items > div.emptyrow:first-child {
    border-top: none;
}

.agenda-container .agenda-items > div:not(.emptyrow) {
    width: calc(100% - 40px);
    margin-left: 20px;
    border-radius: 6px;
    position: absolute;
    cursor: pointer;
    color: #fff;
    display: flex;
    padding-left: 18px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    border: 3px dashed transparent;
}

.agenda-container .agenda-items > div:not(.emptyrow) > div {
    display: flex;
}

.agenda-container .agenda-items > div:not(.emptyrow) > div span {
    flex: 1;
    font-size: 14px;
}

.agenda-container .agenda-items > div:not(.emptyrow):hover {
    border-color: rgb(208 133 195);
}

.agenda-day-tab {
    height: 100%;
}

.agenda-datum {
    display: inline-block;
    margin-top: 12px;
}

.agenda-datum i {
    margin: 0 10px;
    cursor: pointer;
}

.statusScreen .statusScreen--last {
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    padding: 0;
}

.statusScreen.stopped .statusScreen--last {
    display: none;
}

// .statusScreen .statusScreen--last {
//   padding: 18px 0;
//   background: #ffffff;
// }
.statusScreen .statusScreen--last-wrapper {
    background: #ffffff;
    border: 1px solid #24597a;
    border-radius: 10px;
    width: 100%;
    height: calc(100% - 9px);
    display: flex;
    cursor: pointer;
    color: #24597a;
    position: relative;
    z-index: 2;
}

.confirm{
    height: 50px;
    width: 120px;
}

.statusScreen .statusScreen--last-wrapper i {
    color: #24597a;
    font-size: 33px;
}

.statusScreen--last-wrapper > div {
    display: flex;
    flex-direction: column;
    margin: auto;
    font-size: 16px;
}

// #pageOverall {
// 	overflow: hidden;
// }
a:hover {
    cursor: pointer;
}

#userBar {
    border: 1px solid #02597d;
    display: flex;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#userBar > div {
    flex: 1;
    padding: 8px 10px;
    color: #02597d;
    border-right: 1px solid #02597d;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
}

#userBar > div:last-child {
    border-right: 0;
}

#userBar > div.active {
    background: #02597d;
    color: #fff;
}

#userBar i {
    margin-right: 10px;
}

#userBar i.close-user {
    float: right;
    margin-right: 0px;
    font-size: 40px;
    display: none;
}

#userBar i.close-user.active {
    display: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Lato";
    font-weight: 700;
}

header.masthead {
    position: relative;
    background-color: #343a40;
    // background: url("../src/img/bg-masthead.jpg") no-repeat center center;
    background-size: cover;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.cdk-overlay-container {
    z-index: 4444;
}

::ng-deep .cdk-overlay-container {
    z-index: 2000;
}

header.masthead .overlay {
    position: absolute;
    background-color: #212529;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
}

header.masthead h1 {
    font-size: 2rem;
}

@media (min-width: 768px) {
    header.masthead {
        padding-top: 12rem;
        padding-bottom: 12rem;
    }
    header.masthead h1 {
        font-size: 3rem;
    }
}

.showcase .showcase-text {
    padding: 3rem;
}

.showcase .showcase-img {
    min-height: 30rem;
    background-size: cover;
}

@media (min-width: 768px) {
    .showcase .showcase-text {
        padding: 7rem;
    }
}

.features-icons {
    padding-top: 0.25rem;
    padding-bottom: 1rem;
}

.features-icons .features-icons-item {
    max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
    height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
    font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
    font-size: 5rem;
}

.testimonials {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.testimonials .testimonial-item {
    max-width: 18rem;
}

.testimonials .testimonial-item img {
    max-width: 12rem;
    -webkit-box-shadow: 0px 5px 5px 0px #adb5bd;
    box-shadow: 0px 5px 5px 0px #adb5bd;
}

.call-to-action {
    position: relative;
    background-color: #343a40;
    //  background: url("../src/img/bg-masthead.jpg") no-repeat center center;
    background-size: cover;
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.call-to-action .overlay {
    position: absolute;
    background-color: #212529;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
}

.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.container {
    max-width: 1140px;
}

.navbar {
    height: 100px;
    .container {
        max-width: 1140px;
    }
}




span,
p,
a,
button,
label,
.titleBar,
footer {
    font-family: Lato !important;
}

h1 {
    color: #24597a;
    font-family: Lato !important;
    font-size: 24px !important;
    font-weight: 400 !important;
}

h2 {
    font-family: Lato !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

h3 {
    font-family: Lato !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

h4 {
    font-family: Lato !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.main-page {
    height: calc(100vh - 180px);
    #Bar {
        border: 1px solid #02597d;
        display: flex;
        box-shadow: inset 0px 2px 4px rgb(0 0 0 / 20%);
    }
    .mainScreen {
        // height: calc(100% - 60px) !important;
        display: flex;
    }
    button.circle-small {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: none;
        margin: 10px;
    }
}

.red {
    background-color: red;
}

.yellow {
    background-color: yellow;
}

.green {
    background-color: green;
}

.blue {
    background-color: blue;
}

.fa {
    cursor: pointer;
}

@media (min-width: 1440px) {
    span.button-text-large {
        font-size: 24px;
    }
}

.myCustomModalClass .modal-dialog {
    .iframe {
        width: 60vw;
        height: 80vh;
        overflow-y: hidden;
    }
    height: 90%;
    width: 90%;
    max-width: 1800px;
    max-height: 1000px;
}

.btn-primary {
    border-radius: 0 !important;
    font-family: Lato !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    color: #fff;
    background-color: #387d24 !important;
    border-color: #387d24 !important;
}

.btn-outline-dark {
    border-radius: 0 !important;
    font-family: Lato !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.btn-secondary {
    border-radius: 0 !important;
    font-family: Lato !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    color: black !important;
    background-color: white !important;
    border-color: black !important;
}

.form-control:focus {
    border-color: #387d24;
    box-shadow: 0 0 0 0.25rem rgb(56 125 36 / 25%) !important;
}

app-login,
app-forgot-password {
    + app-footer,
    app-footer {
        display: none;
    }
}

.has-sidebar + app-footer {
    .footer {
        margin-left: calc($sidebarWidthXs - $dashboardSpacingXs);
        @media (min-width: $sm) {
            margin-left: calc($sidebarWidthSm - $dashboardSpacingSm);
        }
        @media (min-width: $xxl) {
            margin-left: calc($sidebarWidthLg - $rowPadding);
        }
    }
}

app-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 62px;
    left: 0;
    margin-left: 170px;
}

@media (max-height: 500px) {
    app-root {
        position: relative;
        min-height: 500px;
    }
    app-footer {
        position: absolute;
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Lato;
}

// html,
// body {
//     height: 100%;
// }

body {
    margin: 0;
    font-family: Lato;
}

.is-invalid {
    border: 1px solid red !important;
}

.eventOrder {
    font-size: 1rem;
    text-align: center !important;
}

.OrderID {
    margin-bottom: auto;
}

.orderline {
    width: 45% !important;
    max-width: 45% !important;
}

.accordion-block {
    margin-top: 20px;
}

.custom-accordion {
    .accordion-item {
        border-radius: 0px !important;
        margin-bottom: 10px;
        background-color: #24597a !important;
    }
    .accordion-button {
        border-radius: 0px !important;
        background-color: #24597a !important;
        height: 45px;
        color: #fff !important;
        padding: 10px;
        box-shadow: none !important;
    }
    .accordion-button::after {
        background-image: url(./assets/angle-right-arrow.png) !important;
        transform: none !important;
        background-size: 14px;
        background-position: right;
    }
    .accordion-button.collapsed {
        background-color: #e0e0e0 !important;
        // color: #000 !important;
        color: #24597a !important;
        font-size: 24px !important;
    }
    .accordion-button.collapsed::after {
        background-image: url(./assets/angle-arrow-down.png) !important;
        background-size: 14px;
        background-position: right;
    }
    .accordion-body {
        padding: 10px 10px 0px 10px !important;
        p {
            cursor: pointer;
        }
        background-color: #24597a;
        color: white;
    }
}

.instructions {
    max-width: 90% !important;
    .modal-content {
        height: 90vh;
    }
}

.attention {
    display: none;
    width: 50px;
    height: 50px;
    background: #0e405f !important;
    border-radius: 50%;
    position: absolute;
    z-index: 10 !important;
}

.reduce-height{
    height: 98%;
}
.increase-height{
    height: 100%;

} 
mat-keyboard-container{
    max-width: 768px!important;
    min-width: 300px!important;
}

// body.keyboard {
//     height: calc(100% + 500px); /* add padding for keyboard */
// }


@media screen and (min-device-width: 1025px) and (max-device-width: 1250px) { 
    .has-sidebar + app-footer .footer{
        margin-left: 270px;
    }
}
@media screen and (min-device-width: 1251px) and (max-device-width: 1400px) { 
    .has-sidebar + app-footer .footer{
        margin-left: 270px;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) { 
    .titleBar{
        font-size: 16px !important;
      }
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1250px) { 
    .titleBar{
        font-size: 20px !important;
      }
}
@media screen and (min-device-width: 1251px) and (max-device-width: 1399px) { 
    .titleBar{
        font-size: 22px !important;
      }
}
